import { defineStore } from "pinia";
import { ref } from "vue";
import api from "../../../api/api";
import type { UserActivationData } from "../../../api/api.types";
import throwError from "@/utils/helpers/throwError";

export const useActivateStore = defineStore("activateStore", () => {
  const activateData = ref({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    locale: "pl",
    regulationsAgreement: false,
    personalDataAgreement: false,
    marketingAgreement: false,
    password: "",
    confirmPassword: "",
    prefilledEmail: false,
    prefilledPhoneNumber: false,
    isRestaurant: false,
    createdByAdmin: false,
    id: 0,
  });

  const getActivateData = async (confirmationToken: string) => {
    try {
      const {
        data: { user },
      } = await api.getActivateData(confirmationToken);
      activateData.value = {
        ...activateData.value,
        ...user,
        prefilledEmail: !!user.email,
        prefilledPhoneNumber: !!user.phoneNumber,
      };
    } catch (error) {
      throwError(error);
    }
  };

  const generateOtgCode = async (phone: string) => {
    try {
      await api.generateOtgCode(phone);
    } catch (error) {
      throwError(error);
    }
  };

  const activateUser = async (user: UserActivationData) => {
    const {
      user: { confirmationToken },
    } = user;

    try {
      Promise.allSettled([await api.userActivate(user), await api.userAuthenticate({ confirmationToken })]);
    } catch (error) {
      throw new Error("error");
    }
  };

  const updateUserEmail = async (email: string) => {
    try {
      await api.updateUserEmail(email);
    } catch (error) {
      throw new Error("error");
    }
  };

  const updateUserPhoneNumber = async (phone: string, otgCode: string) => {
    try {
      await api.updateUserPhoneNumber({ user: { phoneNumber: phone }, otgCode });
    } catch (error) {
      throw new Error("error");
    }
  };

  return {
    activateData,
    getActivateData,
    activateUser,
    updateUserPhoneNumber,
    updateUserEmail,
    generateOtgCode,
  };
});
