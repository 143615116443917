const countriesConfig = [
  {
    countryCode: "PL",
    helpCenterLink: "https://help.smartlunch.com/pl",
    contact: {
      phone: "+48 515 162 005",
      email: "biuro@smartlunch.pl",
    },
    privacyPolicyLink: "https://www.smartlunch.pl/polityka-prywatnosci",
    termsOfServiceLink: "https://www.smartlunch.pl/regulamin",
    workingTime: { open: "8:00", close: "18:30" },
  },
  {
    countryCode: "CZ",
    helpCenterLink: "https://help.smartlunch.com/cs",
    contact: {
      phone: null,
      email: "podpora@smartlunch.com",
    },
    privacyPolicyLink: "https://smartlunch.com/cs/privacy-policy",
    termsOfServiceLink: "https://smartlunch.com/cs/terms-and-conditions",
    workingTime: { open: "9:00", close: "18:30" },
  },
];
export default countriesConfig;
